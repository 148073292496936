import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AppStorageService } from '../../../../../core/services/app-storage/app-storage.service';
import { ConnectWalletComponent } from '../../connect-wallet/connect-wallet.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, ConnectWalletComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  public username: string | null = null;

  constructor(
    private router: Router,
    private storageService: AppStorageService
  ) {}

  public ngOnInit(): void {
    this.username = this.storageService.getUsername();
  }

  public goToProfile(): void {
    this.router.navigate(['profile']);
  }

  public logOut(): void {
    this.storageService.clear();
    this.router.navigate(['']);
  }
}
