import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { ACCESS_TOKEN_KEY } from './shared/constants/auth.constants';
import { authInterceptor } from './shared/interceptors/auth-interceptor';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FiltersEffects } from './store/filters/filters.effects';
import { filtersReducer } from './store/filters/filters.reducers';
import { TradePostEffects } from './store/trade-post/trade-post.effects';
import { tradePostReducer } from './store/trade-post/trade-post.reducers';
import { progressTasksReducer } from './store/progress-tasks/progress-tasks.reducers';
import { ProgressTasksEffects } from './store/progress-tasks/progress-tasks.effects';
import { ActiveOrdersListEffects } from './store/active-orders-list/active-orders-list.effects';
import { activeOrdersListReducer } from './store/active-orders-list/active-orders-list.reducers';
export function tokenGetter() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimations(),
    provideToastr({
      timeOut: 2000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing',
      toastClass: 'ngx-toastr ngx-toastr-custom',
    }),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
        },
      }),
      StoreModule.forRoot({
        filters: filtersReducer,
        tradePost: tradePostReducer,
        progressTasks: progressTasksReducer,
        activeOrdersList: activeOrdersListReducer,
      }),
      EffectsModule.forRoot(
        FiltersEffects,
        TradePostEffects,
        ProgressTasksEffects,
        ActiveOrdersListEffects
      ),
      !environment.production
        ? StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !isDevMode(),
            autoPause: true,
            trace: true,
            traceLimit: 75,
          })
        : []
    ),

    { provide: LOCALE_ID, useValue: 'es-ES' },
    provideEnvironmentNgxMask(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase()),
  ],
};
