<div class="app-header" role="banner">
  <div class="row align-items-center justify-content-between">
    <div class="col-4">
      <div
        class="d-flex primary fw-bold flex-grow-1 pointer"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="/assets/img/icons/person-icon.svg" />
        <span class="username-profile">{{ username }}</span>
        <img class="pt-1" src="/assets/img/icons/arrow-down-icon.svg" />
      </div>

      <ul
        class="dropdown-menu dropdown-list pointer black-scroll-bar"
        aria-labelledby="dropdownMenuButton1"
      >
        <li (click)="goToProfile()"><div class="dropdown-item">Perfil</div></li>
        <li (click)="logOut()">
          <div class="dropdown-item">Cerrar sesión</div>
        </li>
      </ul>
    </div>
    <div class="col-4 text-center">
      <img src="/assets/img/pocket-logo-blue-145-38.svg" />
    </div>
    <div class="col-4 text-end">
      <app-connect-wallet></app-connect-wallet>
    </div>
  </div>
</div>
