import { createActionGroup, props } from '@ngrx/store';
import { TaskState, TaskTypeEnum } from '../../shared/models/error-state.model';

export const progressTaskActions = createActionGroup({
  source: 'ProgressTasks',
  events: {
    addTask: props<{ taskType: TaskTypeEnum }>(),
    removeTask: props<{ taskType: TaskTypeEnum }>(),
    addError: props<{ errorType: TaskTypeEnum; error: TaskState }>(),
    removeError: props<{ errorType: TaskTypeEnum }>(),
  },
});
