import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../molecules/header/header/header.component';

@Component({
  selector: 'app-main-router',
  standalone: true,
  imports: [RouterModule, HeaderComponent],
  templateUrl: './main-router.component.html',
  styleUrl: './main-router.component.scss',
})
export class MainRouterComponent {}
