import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tradePostActions } from './trade-post.actions';
import { Store } from '@ngrx/store';
import { exhaustMap, finalize, map } from 'rxjs';
import { TradeService } from '../../core/services/trade/trade.service';
import {
  CryptoPost,
  TradeOperationStatusEnum,
} from '../../shared/models/crypto-post.model';
import { ServiceResponseHandler } from '../../shared/models/service.model';
import { TaskTypeEnum } from '../../shared/models/error-state.model';
import { progressTaskActions } from '../progress-tasks/progress-tasks.action';
import { OrderListHandlerService } from '../../core/services/order-list-handler/order-list-handler.service';

@Injectable()
export class TradePostEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private tradeService: TradeService,
    private orderListHandlerService: OrderListHandlerService
  ) {}
  createOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tradePostActions.createOrder),
      exhaustMap(({ postType, data }) => {
        this.store.dispatch(
          progressTaskActions.addTask({ taskType: TaskTypeEnum.TRADE_CREATE_ORDER })
        );
        return this.tradeService.createOperation(postType, data).pipe(
          map(({ error, response }: ServiceResponseHandler<CryptoPost>) => {
            if (error) {
              return progressTaskActions.addError({
                errorType: TaskTypeEnum.TRADE_CREATE_ORDER,
                error: {
                  id: '0001',
                  message: 'No se logró crear la operación',
                  error: error,
                },
              });
            } else {
              this.store.dispatch(
                progressTaskActions.removeError({
                  errorType: TaskTypeEnum.TRADE_CREATE_ORDER,
                })
              );
              // this.firebaseDataService.setStatus(
              //   {
              //     nodeType: FirebaseNodeType.TRANSACTION,
              //     postType: postType,
              //     postId: response?.idSellPost || response?.idBuyPost || '',
              //     transactionId: response?._id || '',
              //   },
              //   response?.status || TradeOperationStatusEnum.IN_PROGRESS
              // );
              this.orderListHandlerService.listenAllMyPostsChanges();
              return tradePostActions.setTradeData({
                tradeData: {
                  status: response?.status || TradeOperationStatusEnum.IN_PROGRESS,
                  transactionId: response?._id,
                },
              });
            }
          }),
          finalize(() => {
            this.store.dispatch(
              progressTaskActions.removeTask({
                taskType: TaskTypeEnum.TRADE_CREATE_ORDER,
              })
            );
          })
        );
      })
    )
  );

  notifyUpdateOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tradePostActions.notifyTradeStatusChange),
      exhaustMap(({ postType, transactionId, triggerUsername, status }) => {
        this.store.dispatch(
          progressTaskActions.addTask({ taskType: TaskTypeEnum.TRADE_UPDATE_ORDER })
        );
        return this.tradeService
          .updateStatus(postType, transactionId, triggerUsername, status)
          .pipe(
            map(({ error, response }: ServiceResponseHandler<CryptoPost>) => {
              if (error) {
                return progressTaskActions.addError({
                  errorType: TaskTypeEnum.TRADE_UPDATE_ORDER,
                  error: {
                    id: '0002',
                    message: 'No se logró actualizar el estado de  la operación',
                    error: error,
                  },
                });
              } else {
                this.store.dispatch(
                  progressTaskActions.removeError({
                    errorType: TaskTypeEnum.TRADE_UPDATE_ORDER,
                  })
                );
                // this.firebaseDataService.setStatus(
                //   {
                //     nodeType: FirebaseNodeType.TRANSACTION,
                //     postType: postType,
                //     postId: response?.idSellPost || response?.idBuyPost || '',
                //     transactionId: response?._id || '',
                //   },
                //   response?.status || TradeOperationStatusEnum.PAYED_NOTIFIED
                // );

                return tradePostActions.setTradeStatus({
                  status: response?.status || status,
                });
              }
            }),
            finalize(() => {
              this.store.dispatch(
                progressTaskActions.removeTask({
                  taskType: TaskTypeEnum.TRADE_UPDATE_ORDER,
                })
              );
            })
          );
      })
    )
  );
}
