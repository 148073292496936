import { createReducer, on } from '@ngrx/store';
import { tradePostActions } from './trade-post.actions';
import { TradePostState } from './trade-post.types';
import { CryptoPost } from '../../shared/models/crypto-post.model';
import { PostTypeEnum } from '../../shared/pages/create-post/create-post.model';
import { TradeData, TradeFlowStep } from '../../shared/models/trade.model';
const initialState: TradePostState = {
  postType: PostTypeEnum.Buy,
  postData: {} as CryptoPost,
  tradeData: {
    currentFlowStep: TradeFlowStep.INSERT_VALUES,
  } as TradeData,
};

export const tradePostReducer = createReducer(
  initialState,

  on(tradePostActions.setTradePostState, (state, { tradePostState }) => tradePostState),

  on(
    tradePostActions.updateTradePostState,
    (state, { postType, postData, tradeData, useInitialState }) => {
      const stateToUse: TradePostState = useInitialState ? initialState : state;
      return {
        ...stateToUse,
        postType: postType || state.postType,
        postData: postData || state.postData,
        tradeData: {
          ...stateToUse.tradeData,
          ...tradeData,
        },
      };
    }
  ),

  on(tradePostActions.setPostType, (state, { postType }) => ({
    ...state,
    postType: postType,
  })),

  on(tradePostActions.setPostData, (state, { postData }) => ({
    ...state,
    postData: postData,
  })),

  on(tradePostActions.setTradeData, (state, { tradeData }) => ({
    ...state,
    tradeData: {
      ...state.tradeData,
      ...tradeData,
    },
  })),

  on(tradePostActions.setTradeStatus, (state, { status }) => ({
    ...state,
    tradeData: {
      ...state.tradeData,
      status: status,
    },
  }))
);
