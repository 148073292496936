<div class="d-flex align-items-center justify-content-center register-container">
  <div class="d-flex register-modal">
    <div class="d-flex align-items-center logo-container text-center">
      <img
        src="/assets/img/pocket-logo-slogan-withe-160-49.svg"
        alt="pocket logo"
        class="mx-5"
      />
    </div>
    <div class="d-flex flex-column form-container flex-grow-1">
      <div class="d-flex align-items-center form-header primary fw-bold mx-1">
        <!-- TODO: quitar si ya no se va a permitir volver a la pantalla anterior -->
        <!-- {{ showBackArrow }} -->
        @if (showBackButton) {
          <i class="bi bi-arrow-left pointer ps-2 pe-3" (click)="goToPreviousStep()"></i>
        }
        <span class="flex-fill fs-4">Registro</span>
        @if (showCloseButton) {
          <i class="bi bi-x-lg pointer pe-1" (click)="tryExitFlow()"></i>
        }
      </div>
      <router-outlet />
    </div>
  </div>
</div>

<app-modal-confirmation
  #confirmationModal
  [modalId]="registerConfirmationModalId"
  (confirmEvent)="confirmExitFlow()"
  title="Estás saliendo del registro"
  message="Vas a salir del proceso de registro sin haber terminado de crear tu cuenta."
></app-modal-confirmation>
