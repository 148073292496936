import { Injectable } from '@angular/core';
import { AppStorageService } from '../app-storage/app-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private storageService: AppStorageService) {}

  public async isAuthenticated(): Promise<boolean> {
    // TODO: pendiente validar con kevin como se van a traer datos básicos del usuario
    // validar como se deberían comportar los guardias cuando intente ingresar a la app
    // quitar libreria jose si no se neceita
    return true;
  }
}
