import { createReducer, on } from '@ngrx/store';
import { ProgressTaskState } from './progress-tasks.types';
import { progressTaskActions } from './progress-tasks.action';

export const initialState: ProgressTaskState = {
  tasks: [],
  errors: [],
};

export const progressTasksReducer = createReducer(
  initialState,

  on(progressTaskActions.addTask, (state, { taskType }) => ({
    ...state,
    tasks: [...state.tasks, taskType],
  })),

  on(progressTaskActions.removeTask, (state, { taskType }) => {
    if (state.tasks.includes(taskType)) {
      return {
        ...state,
        tasks: state.tasks.filter((t) => t !== taskType),
      };
    }
    return state;
  }),

  on(progressTaskActions.addError, (state, { errorType, error }) => {
    if (error.error) {
      console.error(error.error);
    }
    return {
      ...state,
      errors: [
        ...state.errors.filter((e) => e.errorType !== errorType),
        { errorType, error },
      ],
    };
  }),

  on(progressTaskActions.removeError, (state, { errorType }) => {
    if (state.errors.find((e) => e.errorType === errorType)) {
      return state;
    }
    return {
      ...state,
      errors: state.errors.filter((e) => e.errorType !== errorType),
    };
  })
);
