import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { PostTypeEnum } from '../../../shared/pages/create-post/create-post.model';
import {
  CreateOrderPostRequest,
  OrderPostRequest,
} from '../../../shared/models/trade.model';
import { ServiceResponseHandler } from '../../../shared/models/service.model';
import {
  CryptoPost,
  TradeOperationStatusEnum,
} from '../../../shared/models/crypto-post.model';
import { environment } from '../../../../environments/environment';
import { AppStorageService } from '../app-storage/app-storage.service';

const endpointEquivalent: { [key in TradeOperationStatusEnum]?: string } = {
  [TradeOperationStatusEnum.PAID_NOTIFIED]: 'paid-notification',
  [TradeOperationStatusEnum.COMPLETED]: 'received-money?paymentWasReceived=true',
};

@Injectable({
  providedIn: 'root',
})
export class TradeService {
  constructor(
    private http: HttpClient,
    private storageService: AppStorageService
  ) {}
  public createOperation(
    postType: PostTypeEnum,
    data: CreateOrderPostRequest
  ): Observable<ServiceResponseHandler<CryptoPost>> {
    const loggedInUsername = this.storageService.getUsername() || '';
    const baseEndpoint = this.getBaseEndpoint(
      postType,
      loggedInUsername,
      loggedInUsername
    );
    const payload = this.getBody(data);
    return this.http
      .post<{
        response: CryptoPost;
      }>(`${environment.backendUrl}/${baseEndpoint}/create`, payload)
      .pipe(
        map((res) => {
          return { response: res.response };
        }),
        catchError((error) => of({ error }))
      );
  }

  public updateStatus(
    postType: PostTypeEnum,
    transactionId: string,
    triggerUsername: string,
    status: TradeOperationStatusEnum
  ): Observable<ServiceResponseHandler<CryptoPost>> {
    const loggedInUsername = this.storageService.getUsername() || '';
    const baseEndpoint = this.getBaseEndpoint(
      postType,
      triggerUsername,
      loggedInUsername
    );
    const operationEndpoint = endpointEquivalent[status] || '';

    const payload = {
      idBuyPostTransaction: transactionId,
      idSellPostTransaction: transactionId,
    };

    return this.http
      .put<{
        response: CryptoPost;
      }>(`${environment.backendUrl}/${baseEndpoint}/${operationEndpoint}`, payload)
      .pipe(
        map((res) => {
          return { response: res.response };
        }),
        catchError((error) => of({ error }))
      );
  }

  private getBaseEndpoint(
    postType: PostTypeEnum,
    triggerUsername: string,
    loggedInUsername: string
  ): string {
    if (postType === PostTypeEnum.Buy && loggedInUsername === triggerUsername) {
      return 'buyPostTransaction';
    } else if (postType === PostTypeEnum.Buy && loggedInUsername !== triggerUsername) {
      return 'sellPostTransaction';
    } else if (postType === PostTypeEnum.Sell && loggedInUsername === triggerUsername) {
      return 'sellPostTransaction';
    } else {
      return 'buyPostTransaction';
    }
  }

  private getBody(data: CreateOrderPostRequest | OrderPostRequest): any {
    const payload = {
      idBuyPost: data.postId,
      idSellPost: data.postId,
    };
    if (this.isCreateOrderPostRequest(data)) {
      return {
        ...payload,
        quantity: data.quantity,
        pricePaid: data.pricePaid,
      };
    }

    return payload;
  }

  private isCreateOrderPostRequest(data: any): data is CreateOrderPostRequest {
    return 'quantity' in data && 'pricePaid' in data;
  }
}
