import { createReducer, on } from '@ngrx/store';
import { ActiveOrdersListState } from './active-orders-list.types';
import { activeOrdersListActions } from './active-orders-list.actions';
const initialState: ActiveOrdersListState = {
  ordersList: [],
};

export const activeOrdersListReducer = createReducer(
  initialState,

  on(
    activeOrdersListActions.setActiveOrdersList,
    (state, { activeOrdersList }) => activeOrdersList
  ),

  on(activeOrdersListActions.setOrdersList, (state, { ordersList }) => ({
    ...state,
    ordersList: ordersList,
  }))
);
