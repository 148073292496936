import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../core/services/auth/auth.service';

export const authGuard: CanActivateFn = async () => {
  const auth: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  // TODO: quitar await si no se necesita extraer datos del token
  if (!(await auth.isAuthenticated())) {
    router.navigate(['login']);
    return false;
  }
  return true;
};
