import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ModalConfirmationComponent } from '../../molecules/modal-confirmation/modal-confirmation.component';
import { filter } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { RegisterRouterService } from './service/register-router.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-register-router',
  standalone: true,
  imports: [RouterModule, ModalConfirmationComponent],
  templateUrl: './register-router.component.html',
  styleUrl: './register-router.component.scss',
  animations: [],
})
export class RegisterRouterComponent implements OnInit {
  public registerConfirmationModalId = 'register-confirmation-modal';

  public showBackButton = false;
  public showCloseButton: boolean = true;

  public path?: string;

  constructor(
    private router: Router,
    private location: LocationStrategy,
    private registerRouterService: RegisterRouterService
  ) {}

  // TODO: quitar si ya no se va a permitir volver a la pantalla anterior
  public ngOnInit(): void {
    this.updatePathAndButtons(this.router.url);

    // Escucha los eventos de navegación
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const url = (event as NavigationEnd).url;
        this.updatePathAndButtons(url);
      });

    this.registerRouterService.showCloseButton$
      .pipe(untilDestroyed(this))
      .subscribe((showCloseButton) => (this.showCloseButton = showCloseButton));
  }

  @ViewChild('confirmationModal')
  confirmationModal!: ModalConfirmationComponent;

  private updatePathAndButtons(url: string): void {
    const segments = url.split('/');
    this.path = segments[segments.length - 1];

    this.showBackButton = this.path === 'password';
    this.registerRouterService.updateShowCloseButon(this.path !== 'welcome');
  }

  public tryExitFlow(): void {
    this.confirmationModal.openModal();
  }

  public confirmExitFlow(): void {
    this.router.navigate(['']);
  }

  // TODO: quitar si ya no se va a permitir volver a la pantalla anterior
  public goToPreviousStep(): void {
    const state = this.location.getState() as { [key: string]: any };
    const username = state['username'];

    let navigateTo = 'username';
    switch (this.path) {
      case 'password':
        navigateTo = 'username';
        break;
      case 'create-phrase':
        navigateTo = 'password';
        break;
      case 'verify-phrase':
        navigateTo = 'create-phrase';
        break;
      case 'welcome':
        navigateTo = 'verify-phrase';
        break;

      default:
        navigateTo = 'username';
        break;
    }

    this.router.navigate(['register', navigateTo], { state: { username } });
  }
}
