import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { AppStorageService } from '../../core/services/app-storage/app-storage.service';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const router = inject(Router);
  const appStorage = inject(AppStorageService);

  const excludedUrls = [
    'user/login',
    '/user/reserveUsername',
    '/user/register',
    '/user/activate',
  ];
  if (excludedUrls.some((url) => req.url.includes(url))) {
    return next(req);
  }

  const token: string = appStorage.getToken() || '';
  let request = req;
  if (token) {
    request = req.clone({
      setHeaders: {
        token,
      },
    });
  }

  return next(request).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 403) {
        router.navigateByUrl('/login');
      }
      return throwError(() => err);
    })
  );
};
